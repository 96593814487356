#header {
    position: fixed !important;
    z-index: 9999;
}

#wrapper {
    background-color: #000000;
}

.modal {
    display: block !important;
    z-index: 100000;
}

.page-title {
    background: #101010;
}

.auction-card {
    border-radius: 30px;
    padding: 20px;
}

.auction-card {
    position: relative;
}

.requirements-accordion{
  background: transparent;
}
.requirements-accordion .accordion-item{
  background-color: #191919;
  padding: 5px 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}
.requirements-accordion .accordion-body{
  padding-left: 4.5rem;
}
.accordion-item .number{
  color: #2AE3FD;
  font-size: 25px;
}
.faq-accordion{
  background: transparent;
}
.faq-accordion .accordion-header{
  font-size: 23px;
  font-weight: 400;
}
.faq-accordion p{
  font-size: 20px;
  color: #ffffff;
  border-left: 2px solid #22ffed;
  padding: 10px;
  padding-left: 25px;
  position: relative;
}
.faq-accordion p::before{
  content: "";
  display: block;
  width: 2px;
  background-color: #E82630;
  height: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.faq-accordion i::before{
  background: #181818;
  border-radius: 50%;
}

.find-more{
  min-height: 600px;
}
.find-more img{
  width: 80px;
  height: 80px;
}
.find-more p{
  font-size: 25px;
  max-width: 550px;
}

.auction-card .likes {
    position: absolute;
    background: rgba(255, 255, 255, 0.966);
    padding: 3px 12px;
    border-radius: 20px;
    -webkit-box-shadow: 0px 17px 13px 0px #192c4b05;
    box-shadow: 0px 17px 13px 0px #192c4b05;
    z-index: 1;
    top: 14px;
    right: 14px;
}

.auction-card .category-tag {
    position: absolute;
    padding: 3px 12px;
    border-radius: 20px;
    -webkit-box-shadow: 0px 17px 13px 0px #192c4b05;
    box-shadow: 0px 17px 13px 0px #192c4b05;
    z-index: 1;
    top: 14px;
    left: 14px;
}

.auction-card .details {
    position: absolute;
    padding: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    background: #0000007c;
    border-radius: 30px;
}

.auction-card .details .avatar {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translate(-50%);
    width: 50px;
    height: 50px;
}

.auction-card .details img {
    border-radius: 10px;
}

.auction-card-sm .place-bid-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.card, .box, .creator_item {
    background-color: #141414 !important;
    border: none;
    border-radius: 20px;
}

.card:hover {
    cursor: pointer;
}

.auction-card .details * {
    color: #ffffff !important;
}

.modal-content {
    background-color: #141414 !important;
    border: none !important;
}

i[class^="ri-"] {
    color: #2ae3fd;
}

.share i[class^="ri-"] {
    color: #000000;
}

.share i[class^="ri-"]:hover {
    color: #2ae3fd;
}

.community .community__items .item .logo * {
    color: #ffffff;
}

.bg-dark {
    background-color: #000000 !important;
}

.rounded {
    border-radius: 30px !important;
}

.status-header {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
}

.text-primary {
    color: #2ae3fd !important;
}

.btn-warning {
    color: #000000;
    background-color: #FFCA2C !important;
    border: 1px solid #FFCA2C !important;
}

.btn-warning:hover,
.btn-warning:active,
.btn-warning:focus {
    background-color: #FFCA2Cc2 !important;
    border: 1px solid #FFCA2C !important;
}

.btn-danger {
    color: #ffffff;
    background-color: #fc2934 !important;
    border: 1px solid #fc2934 !important;
}

.btn-danger:hover,
.btn-danger:active,
.btn-danger:focus {
    background-color: #fc2934c2 !important;
    border: 1px solid #fc2934 !important;
}

.btn-outline-primary {
    color: #ffffff;
    background-color: transparent !important;
    border: 1px solid #2ae3fd !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:focus {
    color: #000000 !important;
    background-color: #2ae3fd !important;
    border: 1px solid #2ae3fd !important;
}


.btn-outline-danger {
    color: var(--white);
    background-color: transparent !important;
    border: 1px solid #fc2934 !important;
}

.btn-outline-danger:hover,
.btn-outline-danger:active,
.btn-outline-danger:focus {
    color: #000000 !important;
    background-color: #fc2934 !important;
}

.btn-primary i[class^="ri-"] {
    color: #000000;
}

.text_bold {
    font-weight: bold;
}

.item_details .item_img {
    max-height: 533px;
}

.left__part.upload_file {
    padding: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: 4px dashed #2ae3fd;
    border-radius: 20px;
}

.partners .card img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.partners .card .card-body {
    width: 100%;
    height: 100%;
}

.partners img {
    max-width: 220px;
    max-height: auto;
}

.hero__profile .cover,
.hero__banner .cover {
    background: linear-gradient(180deg, #2AE3FD 24.12%, #FC2934 100%);
}

.banner-btn-container {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.modal-content {
    border: 1px solid #ffffff !important;
}

.staking-modal {
    max-width: 580px !important;
}

.nav-item .btn:not(.active) {
    background-color: transparent !important;
    border: 1px solid rgb(155, 151, 151) !important;
    color: #ffffff !important;
}

.nav-item .btn:not(.active):hover {
    border: 1px solid #2AE3FD !important;
}

.nav-item .btn.active {
    background-color: #2AE3FD !important;
    border: 1px solid #2AE3FD !important;
}

.max-input {
    display: flex;
    border: none;
}

.max-input input {
    background-color: transparent;
    color: #000;
    border: none;
}

.max-input input:focus,
.max-input input:active {
    background-color: transparent;
    box-shadow: none;
    border: none;
}

.max-input button {
    background-color: transparent;
    border: none;
    color: #ffffff;
}

.copy-btn:hover {
    cursor: pointer;
}

input[type="date"] {
    padding: 17px;
}

select, input[type="date"] {
    border-radius: 14px !important;
}

.paragraph {
    position: absolute;
    max-width: 40%;
    right: 20%;
    top: 15%
}

.paragraph p {
    font-size: 28px;
    color: #ffffff;
}

.paragraph .left,
.paragraph .right {
    width: 35px;
    height: 35px;
    position: absolute;
}

.paragraph .left {
    top: 0;
    left: -60px;
}

.paragraph .right {
    right: -35px;
    bottom: 40px;
}

.vector-top,
.vector-bottom {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    right: 0;
}

.vector-top {
    top: -280px;
}

.vector-bottom {
    bottom: -125px
}

.about-hero {
    position: relative;
    min-height: 680px;
    display: flex;
    align-items: center;
}

.about-hero p{
 max-width: 70%; 
 color: #ffffff; 
 font-size: 35px;
 margin: 0;
}

.about-hero img {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%)
}

.absolute-section {
    margin: 300px 0px;
}

.p-lg {
    font-size: 32px;
}

.creator_item {
    background-color: transparent !important;
}

ul.timeline {
    list-style-type: none;
    position: relative
}

ul.timeline:before {
    content: ' ';
    background: #22ffed;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400
}

ul.timeline > li {
    margin: 20px 0;
    padding-left: 90px;
    position: relative;
}

ul.timeline > li div {
    background-color: #141414;
}

ul.timeline > li .date {
    background: linear-gradient(45deg, #22ffed, #ff0101), linear-gradient(310deg, #070707, black), linear-gradient(96deg, red, #12121200);
    position: relative;
    width: 200px;
    height: 200px;
}

ul.timeline > li:before {
    content: ' ';
    background: #22ffed;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22ffed;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
    top: 50%;
    transform: translateY(-50%);
}

ul.timeline > li .date:before {
    content: ' ';
    background: #22ffed;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22ffed;
    left: -10px;
    width: 10px;
    height: 15px;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

ul.timeline > li .date:after {
    content: ' ';
    background: #22ffed;
    display: inline-block;
    position: absolute;
    border: 1px solid #22ffed;
    left: -50px;
    width: 50px;
    height: 1px;
    z-index: 400;
    top: 50%;
    transform: translateY(-50%);
}

.timeline .details ul {
    list-style: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.timeline .details li {
    list-style: none;
}

.timeline .details ul li::before {
    content: "";
    background-color: #22ffed;
    font-weight: bold;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    display: inline-block;
}

.soon a,
.soon a:hover {
    color: #ffffff !important;
}

.soon {
    position: relative;
}

.soon::before {
    content: "SOON";
    text-align: center;
    line-height: 15px;
    height: 12px;
    background-color: #E82630;
    color: #000000;
    font-size: 8px;
    font-weight: bold;
    padding: 3px 8px;
    border-radius: 20px;
    position: absolute;
    top: -8px;
    left: 75px;
    transform: translateX(-50%);
    vertical-align: middle;
    display: flex;
    line-height: 6px;
}

.card-header {
    font-size: 24px;
    background-color: #2AE3FD;
    color: #000000;
}

.card-header.secondary {
    background-color: #2ae4fd7c;
}

.gradient-bg {
    background: linear-gradient(0deg, rgba(42, 227, 253, 0.4) 0%, rgba(20, 20, 20, 0.4) 100%);
}

.card-body {
    border-radius: 20px;
}

.plan-card {
    border-radius: 20px;
    min-height: 576px;
    border: 1px solid rgba(42, 227, 253);
}

.plan-card .card-header {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.plan-card p {
    font-size: 16px;
    text-align: center;
}

.plan-card.secondary {
    border: 1px solid rgba(42, 227, 253, 0.5);
}

.plan-list li {
    font-size: 16px;
    align-items: center;
    line-height: 20px;
}

.plan-list li::before {
    content: '';
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 15px;
    background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='15' fill='%232AE3FD' fill-opacity='0.3'/%3E%3Ccircle cx='15' cy='15' r='8' fill='%232AE3FD'/%3E%3C/svg%3E");
    background-size: cover;
}

.coming-soon-section .text {
    min-height: 800px;
    z-index: 999;
}

.coming-soon-section * {
    z-index: 999;
    color: #ffffff;
}

.coming-soon-section p {
    font-size: 35px;
    width: 950px;
}

.coming-soon-section .footer {
    font-size: 24px;
}


.home-paragraph {
    position: relative;
    width: 950px;
    margin-right: auto;
    margin-left: auto;
}

.home-paragraph .left,
.home-paragraph .right {
    width: 35px;
    height: 35px;
    position: absolute;
}

.home-paragraph .left {
    top: 0;
    left: -60px;
}

.home-paragraph .right {
    right: -70px;
    bottom: 40px;
}

.service-card {
    background: linear-gradient(180deg, #177D8C 0%, rgba(23, 125, 140, 0) 100%);
    border: 1px solid #07272B;
    border-radius: 20px;
    text-align: center;
    cursor: default !important;
    height: 100%;
}

.service-card p {
    color: #6F7D95;
    font-size: 14px;
    min-height: 45px;
}

.service-card img {
    width: 60px;
    height: 60px;
    background-color: #ffffff;
    border-radius: 50%;
    padding: 5px;
    margin-bottom: 15px;
    margin-right: auto;
    margin-left: auto;
}

.slider-pagination {
    display: flex !important;
    position: absolute;
    bottom: 110px;
    left: 180px;
}

.slider-pagination li.slick-active .number-back {
    opacity: 1;
    transition: all .7s;
}

.slider-pagination li:not(.slick-active) .number-back {
    opacity: 0;
    transform: scale(0.8);
    transition: all .7s;
}

.slider-pagination .number-container {
    position: relative;
    margin-right: 95px;
}

.slider-pagination .number-container:hover {
    cursor: pointer;
}

.slider-pagination .number-back {
    color: #54545484;
    font-size: 52px;
    font-weight: 900;
    position: absolute;
    left: 5px;
    bottom: -10px;
    z-index: 1;
}

.slider-pagination .page-num {
    bottom: 0;
    font-size: 24px;
    color: #ffffff;
    z-index: 999;
    position: absolute;
}

.progress {
    background-color: #141414;
}

.progress-inner {
    background: linear-gradient(90deg, #2AE3FD 63%, #F33233 83%);
}

.iai_slide_logo {
    width: 100px;
    margin-right: auto;
    margin-left: auto;
}

.title-backdrop {
    position: absolute;
    bottom: -12px;
    color: #141414;
    font-size: 90px;
    font-weight: 900;
    white-space: nowrap;
}

.title-backdrop.right {
    right: 0
}

.title-backdrop.left {
    left: 0
}

#intro-text {
    position: relative;
    padding-left: 500px;
    height: 700px;
}

.article_page .content .inner p {
    color: #ffffff !important;
}

.shimmer-card {
    background: #000000 !important;
    background-color: #000000 !important;
}

.artworks .space-y-10 {
    padding: 0 10px;
}

.switch {

}

.switch-handle {
    background-color: rgb(71, 79, 121);
    border-color: rgb(71, 79, 121);
    box-shadow: rgb(71, 79, 121) 0px 0px 0px 9.5px inset;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}

.switch-handle small {
    background-color: rgb(255, 255, 255);
    transition: background-color 0.4s ease 0s, left 0.2s ease 0s;
}
.system-message {
  position: fixed;
  top: 100px;
  right: 25px;
  /* transform: translateX(-50%); */
  background: #141414;
  color: #ffffff;
  padding: 20px 25px;
  border-radius: 20px;
  cursor: pointer;
  z-index: 9999;
  font-size: 18px;
  font-weight: 200;
  border: 2px solid #fee7a1;
  display: flex;
  justify-content: center;
  text-align: center
}

.system-messag.error{
  border: 3px solid #E82630;
}

.system-messag.success{
  border: 3px solid #ffdc74;
}

form .collapse-section {
    height: 510px;
    opacity: 1;
    transition: all 1s;
}

form .collapse-section.collapsed {
    height: 0px;
    opacity: 0;
    overflow: hidden;
    transition: all 1s;
}

.card.blog .blog-excerpt{
    overflow: hidden;
    max-height: 55px;
}
.modal.fade.show{
    background: #00000080;
}


.social-widget .social_link i {
    color: #ffffff !important;
}

.progress{
    width: 100%;
    height: 6px;
    border-radius: 10px;
}
.progress-inner{
    background-color: linear-gradient(90deg, #2AE3FD 63%, #F33233 83%); 
    width: 70%; 
    height: 100%;
}

.coming-soon{
    font-size: 32px;
    text-align: center;
}

.terms ul{
    padding-left: 40px;
}
.terms li{
    list-style: disc;
    margin-bottom: 5px;
}
.terms h3{
    margin-bottom: 10px;
}
.terms p{
    margin-bottom: 20px;
}

.youtube-embed{
    box-shadow: -25px 26px 0px -2px rgba(32,247,255,1);
    height: 550px;
    border: 1px solid #606060;
}

.form-error input{
    border: 2px solid #E82630 !important;
}
.form-error::after{
    content: 'Please fill this field.';
    color: #E82630;
    font-size: 15px;
}

.spin{
    animation: spin 700ms infinite linear !important;
    height: max-content;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

@media screen and (max-width: 1920px) {
    .p-lg{
        max-width: 80%;
    }
}

@media screen and (max-width: 768px) {

    .header__1 .header__avatar .avatar_popup {
        right: unset;
        left: 0;
    }
    .find-more img{
        z-index: 0;
        opacity: 0.3;
    }

    .youtube-embed{
        box-shadow: -12px 20px 0px -2px rgba(32,247,255,1);
        height: 220px;
        border: 1px solid #606060;
    }
    .youtube-embed iframe{
        height: 220px;
    }
    .about-hero{
        margin: 0 !important;
    }
    .about-hero p{
        font-size: 22px;
        max-width: 100%;
    }
    .soon::before {
        display: none;
    }

    .soon::after {
        margin-left: 10px;
        content: "Soon";
        text-align: center;
        width: 30px;
        height: 12px;
        position: relative;
        display: inline-block;
        border-radius: 20px;
        background-color: #E82630;
        color: #000000;
        font-size: 8px;
        font-weight: bold;
        padding: 2px 2px;
        line-height: 6px;

    }

    ul.timeline > li::before {
        top: 100px;
    }

    ul.timeline > li .date {
        width: 100%;
    }

    p.lead {
        font-size: 16px !important;
    }

    .auction-card .txt {
        font-size: 13px;
    }

    .auction-card .txt_lg {
        font-size: 18px;
    }

    .auction-card .details {
        padding: 24px 18px !important;
    }

    .modal-content {
        max-height: 90vh;
    }

    .modal-body {
        max-height: 100%;
        overflow-y: scroll;
    }


    .home-paragraph {
        width: 435px;
    }

    .coming-soon-section p {
        width: 500px;
        font-size: 22px;
    }

    .title-backdrop {
        font-size: 50px;
        white-space: nowrap;
        bottom: 0px;
    }

    #intro-text {
        position: relative;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        height: 700px;
    }

    .vector-top {
        top: -100px;
    }

    .vector-bottom {
        bottom: -45px
    }

    .absolute-section {
        margin: 115px 0px;
    }

    .home-paragraph {
        width: 100%;
    }
    .home-paragraph p{
        width: 100%;
        font-size: 20px;
    }

    .home-paragraph .left,
    .home-paragraph .right {
        width: 25px;
        height: 25px;
    }

    .home-paragraph .left {
        top: -40px;
        left: 8px;
    }

    .home-paragraph .right {
        right: 8px;
        bottom: 50px;
    }

    .about-hero img{
        top: 5%;
        width: 100px;
    }

    .quote-image{
        width: 100%;
    }

    .paragraph{
        max-width: 100%;
        width: 100%;
        position: relative;
        right: unset;
        left: unset;
        top: unset;
        transform: unset;
    }
    .paragraph p{
        margin-top: 15px;
        font-size: 20px;
        padding: 0 12px;
    }
    .progress{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    } 

    .coming-soon{
        font-size: 30px;
    }

    .slider-pagination {
        display: flex !important;
        position: absolute;
        bottom: 55px;
        left: 12px;
    }

}
@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}
.category-btn span{
    transition: all 0.25s;
}
.category-btn span.active{
    color: #2ae3fd;
    font-weight: bold;
    font-size: 1.05rem;
}