.wallets{
	display: flex;
}
@media (max-width: 768px){
	.wallets{
		flex-direction: column;
	}
	.wallets .btn.btn-grad.btn-sm {
		width: 100% !important;
	}
}

